import { useCallback, useEffect, useState } from "react";

import { useLoadableQuery } from "@apollo/client";
import debounce from "lodash.debounce";

import { DealroomCompanyFilter, LgCompanyFilter } from "@/gql/types.ts";

import {
  SearchCompaniesDealroomDocument,
  SearchCompaniesDealroomQuery,
  SearchCompaniesLiteDocument,
  SearchCompaniesLocalGlobeDocument,
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables,
  SearchCompaniesWithFoundersDocument,
} from "@/app/service/opportunities.gql.ts";

export const START_QUERY_AFTER = 2;

export type SearchType = keyof typeof dict;

const dict = {
  lite: SearchCompaniesLiteDocument,
  founders: SearchCompaniesWithFoundersDocument,
  lg: SearchCompaniesLocalGlobeDocument,
  dealroom: SearchCompaniesDealroomDocument,
};

export const useSearch = (companyType: SearchType = "lg") => {
  const [loading, toggleLoading] = useState(false);
  const [query, queryRef, { reset }] = useLoadableQuery<
    SearchCompaniesLocalGlobeQuery | SearchCompaniesDealroomQuery,
    SearchCompaniesLocalGlobeQueryVariables
  >(dict?.[companyType] || SearchCompaniesDealroomDocument, {
    fetchPolicy: "network-only",
  });

  const debounced = useCallback(
    debounce((filter: LgCompanyFilter | DealroomCompanyFilter) => {
      try {
        query({ filter, limit: 12, offset: 0 });
      } finally {
        toggleLoading(false);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  const search = useCallback(
    async (filter: { name: { fuzzy: string } }) => {
      toggleLoading(true);
      return debounced(filter);
    },
    [debounced],
  );

  return {
    queryRef,
    loading,
    query,
    search,
    reset,
  };
};
